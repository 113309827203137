import { broadcastQueryClient } from "@tanstack/query-broadcast-client-experimental";
import {
  dehydrate,
  hydrate,
  QueryCache,
  QueryClient,
  VueQueryPlugin,
  type VueQueryPluginOptions,
} from "@tanstack/vue-query";

export default defineNuxtPlugin((nuxt) => {
  const queryClient = new QueryClient({
    defaultOptions: { queries: { staleTime: 1000 * 60 * 5 } },
    queryCache: new QueryCache({
      onError(error, query) {
        handleError(
          error,
          `Query failed ${JSON.stringify(query.queryKey)}`,
          false,
        );
      },
    }),
  });

  const options: VueQueryPluginOptions = {
    queryClient,
  };

  nuxt.vueApp.use(VueQueryPlugin, options);

  if (import.meta.server) {
    nuxt.hooks.hook("app:rendered", () => {
      nuxt.payload.query = dehydrate(queryClient);
    });
  }

  if (import.meta.client) {
    // Keep the client in sync with other tabs
    broadcastQueryClient({ queryClient });
    hydrate(queryClient, nuxt.payload.query);
  }
});
