import type { MetadataVariant } from "~/stores/CartStore";
import type { CartItemDto } from "~/types/core/ecommerce/cart";
import type { CheckoutSessionStatus } from "~/types/core/ecommerce/checkout";
import type { CoreUserSource } from "~/types/core/external/session";

export function createSimpleCartItemMapper(
  totStudents: number,
  source?: CoreUserSource,
) {
  return <TVariant extends string>(
    item: CartItemDto,
    variant: MetadataVariant<TVariant> | undefined,
  ): SimpleCartItem => {
    // TODO: Verify that variant subscription plan is correct and matches the cart item

    let price: SimpleCartItem["price"];
    if (variant) {
      const calculatedPrice = calculatePrices(variant, totStudents, source);
      if (calculatedPrice !== "NOT_APPLICABLE") {
        price = calculatedPrice;
      }
    }

    return {
      id: item.uuid,
      isbn: item.productId ?? variant?.isbn,
      amount: item.amount,
      isTrial: false,
      name: variant?.title || variant?.name || item.productName,
      description: isPacketVariant(variant)
        ? [variant?.description, variant?.subtitle].find((it) => !!it)
        : [variant?.subtitle, variant?.description].find((it) => !!it),
      cover: variant?.cover?.img,
      price,
      fixedAmount: variant?.fixedAmount ?? false,
      type: isPacketVariant(variant) || variant?.formats?.includes("nettsted")
        ? "Digitalt læremiddel"
        : "Digitalbok",
    };
  };
}

export function isCheckoutComplete(status: CheckoutSessionStatus | undefined) {
  return !!status && status !== "NEW";
}
